import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'
import { getDPEClass } from '../../utils/helper'
import { Tooltip, Typography } from '@material-ui/core'
import { numberWithSpacesAndCurrency } from '../../localization/number-formatter'

const useStyles = makeStyles((theme) => ({
  extraTitle: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginBottom: theme.spacing(3),
      fontSize: theme.typography.pxToRem(13),
    },
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(15),
    },
  },
  title: {
    fontWeight: 'bold',
  },
  warning: {
    fontWeight: 'bold',
    fontStretch: 'italic',
  },
  detailsValue: {
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'right',
    },
  },
}))

export const PropertyExtraDetailsDPESliders: React.FC<{ data: any }> = ({ data }: any) => {
  const classes = useStyles()
  const intl = useIntl()

  const arrowsDefintion = [
    {//0:
      letter: 'A',
      letterPosition: '200',
      poly: '10,10 250,10 290,50 250,90 10,90',
      rangeEnergetique: "<70",
      rangeGes: "<6",
      colorEnergetique: '#118f0a',
      colorGes: '#88d7f6' 
    },
    {//1:
      letter: 'B',
      letterPosition: '250',
      poly: '10,10 300,10 340,50 300,90 10,90',
      rangeEnergetique: "71-110",
      rangeGes: "7-11",
      colorEnergetique: '#80c316',
      colorGes: '#72aed0' 
    },
    {//2:
      letter: 'C',
      letterPosition: '310',
      poly: '10,10 350,10 400,50 350,90 10,90',
      rangeEnergetique: "111-180",
      rangeGes: "12-30",
      colorEnergetique: '#fdee21',
      colorGes: '#6489a4' 
    },
    {//3:
      letter: 'D',
      letterPosition: '360',
      poly: '10,10 400,10 450,50 400,90 10,90',
      rangeEnergetique: "181-250",
      rangeGes: "31-50",
      colorEnergetique: '#faaf3a',
      colorGes: '#526586' 
    },
    {//4:
      letter: 'E',
      letterPosition: '400',
      poly: '10,10 450,10 490,50 450,90 10,90',
      rangeEnergetique: "251-330",
      rangeGes: "51-70",
      colorEnergetique: '#f05a25',
      colorGes: '#474769' 
    },
    {//5:
      letter: 'F',
      letterPosition: '450',
      poly: '10,10 500,10 540,50 500,90 10,90',
      rangeEnergetique: "331-420",
      rangeGes: "71-100",
      colorEnergetique: '#fe1212',
      colorGes: '#322c45' 
    },
    {//6:
      letter: 'G',
      letterPosition: '500',
      poly: '10,10 550,10 590,50 550,90 10,90',
      rangeEnergetique: ">420",
      rangeGes: ">101",
      colorEnergetique: '#bb0504',
      colorGes: '#26142e' 
    },
  ];

  const DPEClass = getDPEClass(data.consommationEnergetique, data.emissionsGes, data.consommationEnergetiqueLabel, data.emissionGesLabel);

  const energyCostSentenceMinMax = intl.formatMessage({ id: 'between' }) + " " + data.energyCostMin + " " + intl.formatMessage({ id: 'and' }) + " " + numberWithSpacesAndCurrency(data.energyCostMax, data.unitPrice, false);
  const energyCostSentenceMin = intl.formatMessage({ id: 'from' }) + " " + numberWithSpacesAndCurrency(data.energyCostMin, data.unitPrice, false);
  const energyCostSentenceMax = intl.formatMessage({ id: 'upto' }) + " " + numberWithSpacesAndCurrency(data.energyCostMax, data.unitPrice, false);
  
  const energyCostSentence = data.energyCostMin && data.energyCostMax ? 
    energyCostSentenceMinMax : 
    !data.energyCostMin && data.energyCostMax ?
      energyCostSentenceMax : 
      data.energyCostMin && !data.energyCostMax ?
      energyCostSentenceMin : 
      " - ";

  return (
    <Grid>
      <Grid container style={{ paddingBottom: '0.6em' }}>
        <Grid item xs={6} className={classes.title}>
          {intl.formatMessage({ id: 'coutEnergie' })}
        </Grid>
        <Grid item xs={6} className={classes.detailsValue}>
          {energyCostSentence}
        </Grid>
      </Grid>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent={'left'}
      >
        <Box
          display="flex"
          flexDirection="column"
          flexGrow="1"
          justifyContent={'left'}
          minWidth="280px"
          width="50%"
          style={{paddingBottom: "15px"}}
        >
          <span className={classes.title}>
            {intl.formatMessage({ id: 'labelConsomationEnergetique' })}
          </span>
          <span>
            {intl.formatMessage({ id: 'labelLogementEconome' })}
          </span>
          {arrowsDefintion.map((def) => (
            <Tooltip 
              key={def.letter + '_tooltipConsomationEnergetique'}
              style={{ zIndex: 0}}
              placement="right"
              open={def.letter == DPEClass.consommationEnergetiqueLetter}
              arrow
              title={
                <React.Fragment>
                  <Typography color="inherit">{DPEClass.consommationEnergetiqueLetter}</Typography>
                </React.Fragment>
              }
            >
              <svg width="150" viewBox="0 0 600 100">
                <polygon 
                  points={def.poly}
                  fill={def.colorEnergetique} 
                  style={def.letter == DPEClass.consommationEnergetiqueLetter ? {
                    stroke: def.colorEnergetique,
                    strokeWidth: '19'
                  } : {}} 
                />
                <text 
                  x="30"
                  y={def.letter == DPEClass.consommationEnergetiqueLetter ? "65" : "63"}
                  fill="white" 
                  style={{
                    fontFamily: 'Roboto', 
                    fill: 'white',
                    font: (def.letter == DPEClass.consommationEnergetiqueLetter ? 'bold 50px sans-serif' : 'bold 40px sans-serif'), 
                  }}
                >
                  {def.letter} - {def.rangeEnergetique}
                </text>
                Letter {def.letter}
              </svg>
            </Tooltip>
          ))}
          <span>
            {intl.formatMessage({ id: 'labelLogementEnergivore' })}
          </span>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flexGrow="1"
          justifyContent={'left'}
          minWidth="280px"
          width="50%"
          style={{paddingBottom: "15px"}}
        >
          <span className={classes.title}>
            {intl.formatMessage({ id: 'labelEmissionsGes' })}
          </span>
          <span>
            {intl.formatMessage({ id: 'labelFaibleEmissionsGes' })}
          </span>
            {arrowsDefintion.map((def) => (
              <Tooltip 
                key={def.letter + '_tooltipEmissionsGes'}
                style={{ zIndex: 0}}
                placement="right"
                open={def.letter == DPEClass.emissionsGesLetter}
                arrow
                title={
                  <div style={{ zIndex: 0}}>
                    <Typography color="inherit">{DPEClass.emissionsGesLetter}</Typography>
                  </div>
                }
              >
                <svg width="150" viewBox="0 0 600 100"
                      style={{ zIndex: 0}}>
                  <polygon 
                    points={def.poly} 
                    fill={def.colorGes} 
                    style={def.letter == DPEClass.emissionsGesLetter ? {
                      stroke: def.colorGes,
                      strokeWidth: '19'
                    } : {}} 
                  />
                  <text 
                    x="30"
                    y={def.letter == DPEClass.emissionsGesLetter ? "65" : "63"}
                    fill="white" 
                    style={{
                      fontFamily: 'Roboto', 
                      fill: 'white',
                      font: (def.letter == DPEClass.emissionsGesLetter ? 'bold 50px sans-serif' : 'bold 40px sans-serif'), 
                    }}
                  >
                    {def.letter} - {def.rangeGes}
                  </text>
                  Letter {def.letter}
                </svg>
              </Tooltip>
            ))}
            <span>
              {intl.formatMessage({ id: 'labelForteEmissionsGes' })}
            </span>
          </Box>
        </Box>
          <Grid item xs={12} container style={{ paddingTop: '10px' }}>
            <Grid item xs={12} className={classes.warning}>
              {((!data.consommationEnergetique || !data.emissionsGes) && (!data.consommationEnergetiqueLabel || !data.emissionGesLabel)) && intl.formatMessage({ id: 'dpeEnRealisation' })}
            </Grid>
          </Grid>
      </Grid>
  )
}

export default PropertyExtraDetailsDPESliders
