import { Checkbox, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useState } from 'react'
import PhoneIcon from '@material-ui/icons/Phone'

import { useIntl } from 'gatsby-plugin-intl'
import clsx from 'clsx'
import { CustomButton } from '../../CustomButton'
import { submitForm } from '../../../utils/submitForm'
import Link from '@material-ui/core/Link'
import styled from 'styled-components'

const useStyles = makeStyles((theme) => ({
  contactContainer: {
    backgroundColor: theme.palette.info.main,
    padding: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: theme.spacing(2),
    },
  },
  heading: {
    textTransform: 'uppercase',
    fontFamily: `'Gotham bold', serif`,
    fontSize: theme.typography.pxToRem(25),
    fontWeight: 600,
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(15),
    },
  },
  subHeading: {
    textTransform: 'uppercase',
    fontFamily: 'Gotham Medium',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    marginTop: theme.spacing(6),
  },
  container: {
    flexBasis: '50%',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexBasis: '90%',
    },
    [theme.breakpoints.between('md', 'md')]: {
      flexBasis: '80%',
    },
  },
  font: {
    fontSize: theme.typography.pxToRem(12),
    marginRight: theme.spacing(1),
  },
  w100: {
    width: '100%',
  },
  w170: {
    width: '130%',
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100%',
    },
  },
  transparent: {
    backgroundColor: 'transparent',
    borderBottom: 'none',
    borderRight: 'none',
  },
  mb2: {
    marginBottom: '10px',
  },
  mt5: {
    marginTop: theme.spacing(4),
  },
  pr3: {
    paddingRight: theme.spacing(3),
  },
  pb2: {
    paddingBottom: '10px',
  },
  pl3: {
    paddingLeft: theme.spacing(3),
  },
  blackColor: {
    color: '#000',
    fontFamily: `'Gotham Book', serif`,
    fontSize: '.9rem',
  },
  consent: {
    fontSize: '.8em',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
  },
}))

const rePhone = /\s*(?:\+?(\d{1,3}))?[\W\D\s]^|()*(\d[\W\D\s]*?\d[\D\W\s]*?\d)[\W\D\s]*(\d[\W\D\s]*?\d[\D\W\s]*?\d)[\W\D\s]*(\d[\W\D\s]*?\d[\D\W\s]*?\d[\W\D\s]*?\d)(?: *x(\d+))?\s*$/

const PhoneForm = ({ reference='' }) => {
  const classes = useStyles()
  const intl = useIntl()
  const [formValue, setFormValue] = useState({
    phone: '',
    form: 'FC1',
    consent: true,
    reference,
  } as any)
  const [error, setError] = useState(['initial'])
  const [submited, setSubmited] = useState(false)


  const handleChange = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown; textContent?: any; checked?: boolean }>) => {
      if (event && event.target && (event.target.name || event.target.textContent)) {
        setError([])
        setFormValue({ ...formValue, [event.target.name || 'adresse' as string]: (event.target.name == 'consent' ? event.target.checked : event.target.value) || event.target.textContent })
      }
    },
    [formValue],
  )

  const handleSubmit = async () => {
    const { ...keyToValidate } = formValue
    const validateField = Object.keys(keyToValidate).filter((key: string) => !formValue[key] || (key == 'phone' && !String(formValue[key]).match(rePhone)))
    if (validateField.length) {
      setError(validateField)
    } else {
      const result = await submitForm(formValue)
      if (result) {
        setError([])
        setSubmited(true)
      }
    }
  }

  return (
    <>
      <Grid xs={12} item className={clsx(classes.subHeading)}>
        <PhoneIcon className={classes.font} />
        {intl.formatMessage({ id: 'beCallBack' })}
      </Grid>
      <Grid xs={12} item>
        <TextField
          id="contact-number"
          label={`${intl.formatMessage({ id: 'your' })} ${intl.formatMessage({
            id: 'yourNumber',
          })}`}
          name="phone"
          className={clsx(classes.w100, classes.mb2, classes.blackColor)}
          onChange={handleChange}
          value={formValue.phone}
          error={error.includes('phone')}
          InputLabelProps={{ className: classes.blackColor }}
        />
      </Grid>
      <Grid xs={12} item className={classes.consent} justifyContent="center">
        <Grid style={{textAlign: 'left', paddingTop: '10px', paddingBottom: '6px'}}>
          <span>
            {intl.formatMessage({ id: 'legalConsent.phoneForm.moreInformation' })}
            <Link
              href={
                '/' +
                intl.locale +
                '/legal#privacy-policy'
              }
              underline="none"
            >
              {intl.formatMessage({ id: 'clickHere' })}
            </Link>
          </span>
        </Grid>
      </Grid>
      <Grid xs={12} item className={classes.mt5}>
        {submited ? (
          intl.formatMessage({ id: 'send_confirm' })
        ) : (
          <CustomButton textTransform="inherit" darkBoarder height={'40px'} onClick={handleSubmit}>
            <span style={{ fontSize: '0.8rem', padding: '0 0px', fontWeight: 400 }}>
              {intl.formatMessage({ id: 'send' })}
            </span>
          </CustomButton>
        )}
      </Grid>
    </>
  )
}

export default PhoneForm
